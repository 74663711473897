import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
console.log('process.env.REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
// Use the API base URL from environment or fallback to localhost:8000 for development
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000';

const AuthForm = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const endpoint = isLogin ? '/auth/token' : '/auth/register';
      const body = isLogin
        ? new URLSearchParams({
            username: formData.username,
            password: formData.password
          })
        : new URLSearchParams({
            username: formData.username,
            password: formData.password,
            email: formData.email
          });

      // Use the dynamic base URL
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Authentication failed');
      }

      if (isLogin) {
        // Store the token
        localStorage.setItem('token', data.access_token);
        // Navigate to coffee form
        navigate('/home');
      } else {
        // Show success message and switch to login
        setError('Registration successful! Please login.');
        setIsLogin(true);
        setFormData((prev) => ({
          ...prev,
          password: ''
        }));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
    setFormData({
      username: '',
      password: '',
      email: ''
    });
  };

  return (
    <div
      style={{
        maxWidth: '400px',
        margin: '40px auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: 'white'
      }}
    >
      <h2
        style={{
          marginBottom: '20px',
          fontSize: '24px',
          fontWeight: 'bold',
          textAlign: 'center'
        }}
      >
        {isLogin ? 'Login' : 'Register'}
      </h2>

      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        {!isLogin && (
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          </div>
        )}

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        {error && (
          <div
            style={{
              backgroundColor: error.includes('successful') ? '#dcfce7' : '#fee2e2',
              color: error.includes('successful') ? '#16a34a' : '#dc2626',
              padding: '10px',
              borderRadius: '4px',
              marginBottom: '15px'
            }}
          >
            {error}
          </div>
        )}

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#3b82f6',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
        >
          {isLogin ? 'Login' : 'Register'}
        </button>

        <button
          type="button"
          onClick={toggleMode}
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: 'transparent',
            color: '#3b82f6',
            border: '1px solid #3b82f6',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          {isLogin ? 'Need an account? Register here' : 'Already have an account? Login here'}
        </button>
      </form>
    </div>
  );
};

export default AuthForm;
