import React, { useState } from 'react';
import apiClient from '../api';

const NewCoffeeForm = () => {
  const [formData, setFormData] = useState({
    roaster: '',
    farm: '',
    origin: '',
    varietal: '',
    roast_level: '',
    roast_date: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const roastLevels = [
    'nordic', 'light', 'light-medium', 'medium', 
    'medium-dark', 'dark', 'omni'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');

      const config = {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      const response = await apiClient.post('/coffees', formData, config);
      
      setSuccess(true);
      setFormData({
        roaster: '',
        farm: '',
        origin: '',
        varietal: '',
        roast_level: '',
        roast_date: ''
      });
    } catch (err) {
      console.error('Coffee submission error:', err);
      setError(err.response?.data?.detail || err.message);
    }
  };

  return (
    <div style={{ 
      maxWidth: '500px', 
      margin: '40px auto', 
      padding: '20px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: 'white'
    }}>
      <h2 style={{ marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>Add New Coffee</h2>
      
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Roaster *
          </label>
          <input
            type="text"
            name="roaster"
            value={formData.roaster}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Farm
          </label>
          <input
            type="text"
            name="farm"
            value={formData.farm}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Origin *
          </label>
          <input
            type="text"
            name="origin"
            value={formData.origin}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Varietal *
          </label>
          <input
            type="text"
            name="varietal"
            value={formData.varietal}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Roast Level *
          </label>
          <select
            name="roast_level"
            value={formData.roast_level}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            <option value="">Select roast level</option>
            {roastLevels.map(level => (
              <option key={level} value={level}>
                {level.charAt(0).toUpperCase() + level.slice(1).replace('-', ' ')}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Roast Date
          </label>
          <input
            type="date"
            name="roast_date"
            value={formData.roast_date}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        {error && (
          <div style={{
            backgroundColor: '#fee2e2',
            color: '#dc2626',
            padding: '10px',
            borderRadius: '4px',
            marginBottom: '15px'
          }}>
            {error}
          </div>
        )}

        {success && (
          <div style={{
            backgroundColor: '#dcfce7',
            color: '#16a34a',
            padding: '10px',
            borderRadius: '4px',
            marginBottom: '15px'
          }}>
            Coffee successfully added!
          </div>
        )}

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#3b82f6',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Add Coffee
        </button>
      </form>
    </div>
  );
};

export default NewCoffeeForm;