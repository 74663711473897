import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove authentication token
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      backgroundColor: '#f9fafb', 
      textAlign: 'center' 
    }}>
      {/* Logout Button */}
      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
      }}>
        <button
          onClick={handleLogout}
          style={{
            padding: '10px 15px',
            backgroundColor: '#ef4444',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          Logout
        </button>
      </div>

      {/* Welcome Message */}
      <h1 style={{ fontSize: '32px', marginBottom: '20px' }}>Welcome to BrewTrack</h1>
      
      {/* Buttons */}
      <div style={{ display: 'flex', gap: '20px' }}>
        <button
          onClick={() => navigate('/new-coffee')}
          style={{
            padding: '10px 20px',
            backgroundColor: '#3b82f6',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '18px',
          }}
        >
          Add New Coffee
        </button>
        <button
          onClick={() => navigate('/new-recipe')}
          style={{
            padding: '10px 20px',
            backgroundColor: '#10b981',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '18px',
          }}
        >
          Add New Recipe
        </button>
        <button
          onClick={() => navigate('/new-grinder')}
          style={{
          padding: '10px 20px',
          backgroundColor: '#f59e0b',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '18px',
        }}
      >     
  Add New Grinder
</button>

      </div>
    </div>
  );
};

export default HomePage;
