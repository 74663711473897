import React, { createContext, useState } from 'react';
import api from './api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const register = async (username, password, email) => {
    // Use URLSearchParams for form data
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('email', email);

    await api.post('/auth/register', formData);
  };

  const login = async (username, password) => {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);
    const { data } = await api.post('/auth/token', formData);
    setToken(data.access_token);
    localStorage.setItem('token', data.access_token);  // Add this line
    api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
  };

  return (
    <AuthContext.Provider value={{ token, register, login }}>
      {children}
    </AuthContext.Provider>
  );
};
