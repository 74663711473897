import axios from "axios";

// Define the base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

console.log("ENV URL:", process.env.REACT_APP_API_BASE_URL);

// Log the API_BASE_URL for debugging
console.log("API_BASE_URL:", API_BASE_URL);

// Create an Axios instance with the base URL
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(request => {
  console.log('Full Request Config:', {
    method: request.method,
    baseURL: request.baseURL,
    url: request.url,
    finalURL: new URL(request.url, request.baseURL).toString(),
    headers: request.headers
  });
  return request;
}, error => {
  console.error('Request Error:', error);
  return Promise.reject(error);
});


export default apiClient;
