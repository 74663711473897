import React, { useState, useEffect } from 'react';
import apiClient from '../api';

const NewRecipeForm = () => {
  const brewMethods = ["v60", "kalita", "origami", "espresso", "moka pot", "aeropress", "french press", "other"];

  const initialFormState = {
    coffee_id: '',
    brew_method: '',
    custom_brew_method: '',
    style: '',
    batch_size: '',
    output: '',
    grind_size: '',
    grinder: '',
    water_temp: '',
    bloom_time: '',
    bloom_water: '',
    total_time: '',
    inverted: false,
    tasting_notes: '',
    rating: ''
  };

  const [coffees, setCoffees] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoffees = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');
        
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          baseURL: process.env.REACT_APP_API_BASE_URL,
          url: '/coffees'
        };
        
        console.log('Fetching coffees with config:', config);
        console.log('Full URL:', `${config.baseURL}${config.url}`);
        
        const response = await apiClient.get('/coffees', config);
        console.log('Coffee response:', response);
        setCoffees(response.data);
      } catch (err) {
        console.error('Coffee fetch error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCoffees();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!formData.grinder?.trim()) {
      setError('Grinder is required');
      return;
    }

    try {
      const formattedData = {
        coffee_id: parseInt(formData.coffee_id),
        brew_method: formData.brew_method,
        custom_brew_method: formData.custom_brew_method || '',
        style: formData.style || '',
        batch_size: parseFloat(formData.batch_size),
        output: formData.output ? parseFloat(formData.output) : null,
        grind_size: formData.grind_size ? parseFloat(formData.grind_size) : null,
        grinder: formData.grinder.trim(),
        water_temp: formData.water_temp ? parseFloat(formData.water_temp) : null,
        bloom_time: formData.bloom_time ? parseInt(formData.bloom_time) : null,
        bloom_water: formData.bloom_water ? parseFloat(formData.bloom_water) : null,
        total_time: formData.total_time ? parseInt(formData.total_time) : null,
        inverted: Boolean(formData.inverted),
        tasting_notes: formData.tasting_notes || '',
        rating: formData.rating ? parseInt(formData.rating) : null
      };

      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');

      const config = {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url: '/recipes/'
      };

      console.log('Submitting recipe with config:', config);
      console.log('Full URL:', `${config.baseURL}${config.url}`);
      
      const response = await apiClient.post('/recipes/', formattedData, config);
      console.log('Recipe submission response:', response);
      
      setSuccess(true);
      setFormData(initialFormState);
    } catch (err) {
      console.error('Recipe submission error:', err);
      setError(err.response?.data?.detail || err.message);
    }
  };

  // Rest of your JSX remains exactly the same
  return (
    <div style={{ 
      maxWidth: '500px', 
      margin: '40px auto', 
      padding: '20px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: 'white'
    }}>
      <h2 style={{ marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>Create New Recipe</h2>
      
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Coffee *</label>
          <select
            name="coffee_id"
            value={formData.coffee_id}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            <option value="">Select a coffee</option>
            {coffees.map(coffee => (
              <option key={coffee.id} value={coffee.id}>
                {`${coffee.roaster} - ${coffee.farm || 'Unknown Farm'}`}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Brew Method *</label>
          <select
            name="brew_method"
            value={formData.brew_method}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            <option value="">Select brew method</option>
            {brewMethods.map(method => (
              <option key={method} value={method}>
                {method.charAt(0).toUpperCase() + method.slice(1)}
              </option>
            ))}
          </select>
        </div>

        {formData.brew_method === 'other' && (
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
              Custom Brew Method *
            </label>
            <input
              type="text"
              name="custom_brew_method"
              value={formData.custom_brew_method}
              onChange={handleInputChange}
              required
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          </div>
        )}

        {formData.brew_method === 'aeropress' && (
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <input
                type="checkbox"
                name="inverted"
                checked={formData.inverted}
                onChange={handleInputChange}
              />
              Inverted Method
            </label>
          </div>
        )}

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Grinder *
          </label>
          <input
            type="text"
            name="grinder"
            value={formData.grinder}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Style
          </label>
          <input
            type="text"
            name="style"
            value={formData.style}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Batch Size (g)
          </label>
          <input
            type="number"
            name="batch_size"
            value={formData.batch_size}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Output (g)
          </label>
          <input
            type="number"
            name="output"
            value={formData.output}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Grind Size
          </label>
          <input
            type="number"
            name="grind_size"
            value={formData.grind_size}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Water Temperature (°C)
          </label>
          <input
            type="number"
            name="water_temp"
            value={formData.water_temp}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Bloom Time (seconds)
          </label>
          <input
            type="number"
            name="bloom_time"
            value={formData.bloom_time}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Bloom Water (g)
          </label>
          <input
            type="number"
            name="bloom_water"
            value={formData.bloom_water}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Total Time (seconds)
          </label>
          <input
            type="number"
            name="total_time"
            value={formData.total_time}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Tasting Notes
          </label>
          <textarea
            name="tasting_notes"
            value={formData.tasting_notes}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              minHeight: '100px'
            }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            Rating (1-5)
          </label>
          <input
            type="number"
            name="rating"
            value={formData.rating}
            onChange={handleInputChange}
            min="1"
            max="5"
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
        </div>

        {error && (
          <div style={{
            backgroundColor: '#fee2e2',
            color: '#dc2626',
            padding: '10px',
            borderRadius: '4px',
            marginBottom: '15px'
          }}>
            {error}
          </div>
        )}

        {success && (
          <div style={{
            backgroundColor: '#dcfce7',
            color: '#16a34a',
            padding: '10px',
            borderRadius: '4px',
            marginBottom: '15px'
          }}>
            Recipe successfully created!
          </div>
        )}

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#3b82f6',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Create Recipe
        </button>
      </form>
    </div>
  );
};

export default NewRecipeForm;