// frontend/src/Routes.js
// frontend/src/Routes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthForm from './Register'; // Renamed for clarity
import HomePage from './components/HomePage';
import NewCoffeeForm from './components/NewCoffeeForm';
import AddRecipeForm from './components/AddRecipeForm';
import AddGrinderForm from './components/AddGrinderForm'; // Added the grinder form component

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Route for the login/register page */}
        <Route path="/" element={<AuthForm />} />

        {/* Route for the homepage */}
        <Route path="/home" element={<HomePage />} />

        {/* Route for the Add New Coffee form */}
        <Route path="/new-coffee" element={<NewCoffeeForm />} />

        {/* Route for the Add New Recipe form */}
        <Route path="/new-recipe" element={<AddRecipeForm />} />

        {/* Route for the Add New Grinder form */}
        <Route path="/new-grinder" element={<AddGrinderForm />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
